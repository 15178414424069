import React from 'react';
import Layout from "../../common/Layout";

const Service03 = () => {

    return (
        <Layout>
                    <div className="service-area rn-section-gapTop">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Service 03</span></h3>
                                    </div>
                                    
                                    <div className="content">
                                        <h3 className="title">Sevice Three.</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <p className="mb--10">Description here ...</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </Layout>
    )
}
export default Service03;