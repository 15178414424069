import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/serviice-01.jpg',
        title: 'Service 01',
        description: 'Small description here...',
        link : '/serviceFirst'
    },
    {
        image: '/images/service/serviice-02.jpg',
        title: 'Service 02',
        description: 'Small description here...',
        link : '/serviceSeconde'
    },
    {
        image: '/images/service/serviice-03.jpg',
        title: 'Service 03',
        description: 'Small description here...',
        link : '/serviceThird'
    },
    {
        image: '/images/service/serviice-04.jpg',
        title: 'Service 04',
        description: 'Small description here...'
    },
    {
        image: '/images/service/serviice-05.jpg',
        title: 'Service 05',
        description: 'Small description here...'
    },
    {
        image: '/images/service/serviice-06.jpg',
        title: 'Service 06',
        description: 'Small description here...'
    }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="link">
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to={`${val.link}`} dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    <Link className="btn-default btn-small btn-border" to={`${val.link}`}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;