import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="no-droupdown"><Link to="/">Home</Link></li>
            <li className="no-droupdown"><Link to="/about-us">About</Link></li>

            <li className="with-megamenu"><Link to="/service">Services</Link>
                <div className="rn-megamenu">
                    <div className="wrapper">
                        <div className="row row--0">
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/serviceFirst">Service-01</Link></li>
                                    <li><Link to="/serviceSeconde">Service-02</Link></li>
                                    <li><Link to="/serviceThird">Service-03</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li className="no-droupdown"><Link to="/expertise">Our Expertise</Link></li>

            <li className="no-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
