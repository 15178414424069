import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Privacy from "./pages/Privacy";
import Error from "./pages/Error";
import Terms from "./pages/Terms";

// Elements import Here 
import Service from "./elements/service/Service";
import Contact from "./elements/contact/Contact";
import Expertise from "./elements/expertise/Expertise";
import ServiceFirst from "./elements/service/ServiceFirst";
import ServiceSeconde from "./elements/service/ServiceSeconde";
import ServiceThird from "./elements/service/ServiceThird";
// Import Css Here 
import './assets/scss/style.scss';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/expertise"}`} exact component={Expertise}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                    <Route path={`${process.env.PUBLIC_URL + "/privacy"}`} exact component={Privacy}/>
                    <Route path={`${process.env.PUBLIC_URL + "/terms"}`} exact component={Terms}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service"}`} exact component={Service}/>
                    <Route path={`${process.env.PUBLIC_URL + "/serviceFirst"}`} exact component={ServiceFirst}/>
                    <Route path={`${process.env.PUBLIC_URL + "/serviceSeconde"}`} exact component={ServiceSeconde}/>
                    <Route path={`${process.env.PUBLIC_URL + "/serviceThird"}`} exact component={ServiceThird}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
