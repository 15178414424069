import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import AboutTwo from '../elements/about/AboutTwo';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import BrandTwo from '../elements/brand/BrandTwo';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterFour from '../common/footer/FooterFour';
//import Spline from '../elements/spline/Spline';
//import Mars from './images/bg/Mars.gif'




const Home = () => {
    return (
        <>
            <SEO title="Huminame Management" />
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-650">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-left">
                                    <h1 className="title theme-gradient display-two">A New word <br />  {" "}
                                        <Typed
                                            strings={[
                                                "Begins.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                    
                                            />  

                                    </h1>
                                    
                                    <p className="description">We are an independent management and consulting group.<br/> We help our clients succeed by creating ...</p>
                                    <div className="button-group">
                                        {/*<Link className="btn-default btn-medium btn-border round btn-icon" to="/about-us">New Customer <i className="icon"><FiArrowRight /></i></Link>*/}
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2 ">
                                <div className="thumbnail">
                                   <img src="./images/bg/bg-image-16-mars.png" alt="Banner Images" />
                                    {/*<Spline />
                                     <img src="./images/bg/mars.gif" alt="Mars planet" />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}
                <Separator />

                <AboutTwo image="./images/about/contact-image.jpg" />

                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Expertise."
                                    title = "Benefit from our experience to scale your business."
                                    description = "description here... <br /> "
                                />
                            </div>
                        </div>
                        <AdvanceTabOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />


                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Awesome Clients"
                                    title = "Join our clients Comunity!"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                <FooterFour />
            </main>
        </>
    )
}

export default Home;
